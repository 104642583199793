.slideshow-container {
    width: 100%;
    height: 500px; /* Adjust as needed */
    position: relative;
    overflow: hidden;
}

.slide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    margin-left: 5%;
}

.active-slide {
    opacity: 1;
}
