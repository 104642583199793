@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

body, html {
    overflow-x: hidden;
}

/* Define the fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

body {
    font-family: 'Poppins', sans-serif;
    color: #333;
    line-height: 1.7;
    margin: 0;
    padding: 0;
    background-color: #ece0d2;
}

.page-content.resources-page {
    font-family: 'Poppins', sans-serif;
    color: #333;
    line-height: 1.7;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
    min-height: 100vh; /* Ensure the container takes up the full viewport height */
    width: 100%;
    max-width: 1000px;
    background-color: #ece0d2;
}

h2 {
    font-family: 'Great Vibes', cursive;
    font-size: 2em;
}

.resources-container {
    max-width: 1000px;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 0%
}

/* Apply fade-in animation to children */
.resources-container > * {
    opacity: 0; /* Initially hidden */
    animation: fadeIn 1s ease-in-out forwards; /* Apply fade-in animation */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .resources-container {
        width: 100%;
        max-width: none;
        padding: 10px;
        box-shadow: none;
        margin-top: 0%
    }
}
