@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

body, html {
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Define the fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.content-container > div {
    opacity: 0; /* Initially hidden */
    animation: fadeIn 1s ease-in-out forwards; /* Apply fade-in animation */
}

body, .page-content.home {
    font-family: 'Poppins', sans-serif;
    color: #333;
    line-height: 1.7;
    margin: 0;
    padding: 0;
}

h2 {
    font-family: 'Great Vibes', cursive;
    font-size: 2em;
}

.page-content.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center the content horizontally */
    min-height: 100vh; /* Ensure the container takes up the full viewport height */
    padding: 0px 20px;
    background-color: #ece0d2;
}

.hero-section {
    width: 100%;
    max-width: 1200px;
    height: 190px;
    display: flex;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 0 0; /* Apply border-radius only to the top corners */
    background-color: #f0f0f0;
    position: relative;
}

.hero-section img {
    width: 100%;
    height: auto;
    object-fit: cover; /* Change to cover to ensure the image covers the entire area */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
}


.content-container {
    max-width: 1000px;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.author-section {
    display: flex;
    align-items: center;
    background-color: #f0f0f0; /* Background color for author section */
    padding: 20px;
}

.author-text {
    flex: 1.5;
    font-size: 1.2em;
}

.author-image {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:55px; /* Adjust the value as needed */
    padding: 30px;
}

.shea-contact-image {
    width: 300px; /* Adjust size as needed */
    border-radius: 8px;
    margin-bottom: 10px;
}

.founder-text {
    text-align: center;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.special-thanks-section {
    background-color: #f5f5f5; /* Light gray background */
    padding: 20px;
    margin-top: 30px;
    border-radius: 8px;
}

.special-thanks-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Allow wrapping for mobile layout */
}

.thanks-text {
    flex: 2;
    padding-right: 20px;
    font-size: 1.2em;
    order: 1; /* Ensure text appears before the image */
}

.thanks-image {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    order: 2; /* Ensure image appears after the text */
}

.jennie-image {
    width: 200px; /* Adjust size as needed */
    border-radius: 8px;
    margin-bottom: 10px;
}

.bold-text {
    font-weight: 700;
}

.cursive-text {
    font-family: 'Dancing Script', cursive;
}

.impact-section {
    font-size: 1.2em;
    display: flex;
    align-items: flex-start;
    background-color: #fff; /* Background color for impact section */
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.impact-text {
    flex: 2;
    padding-right: 20px;
}

.impact-images {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px; /* Adjust the value as needed */
}

.project-image {
    width: 100%;
    max-width: 300px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.contact-section {
    padding: 20px;
    background-color: #fff;
    display: flex;
    justify-content: center; /* Center the content horizontally */
}

.contact-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items within the content */
    width: 100%;
    max-width: 800px; /* Set a max-width for better control */
}

.contact-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px; /* Add space below the image */
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Ensure the form takes up the remaining space */
    max-width: 500px; /* Set a max-width for the form */
}

.contact-form label {
    margin-bottom: 5px; /* Reduce bottom margin */
}

.contact-form input,
.contact-form textarea {
    width: 100%; /* Make inputs take up full width */
    max-width: 400px; /* Set a max-width for inputs */
    padding: 10px;
    margin-bottom: 10px; /* Add bottom margin for spacing */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
}

.contact-form textarea {
    resize: none; /* Make textarea non-resizable */
    height: 100px; /* Adjust height as needed */
}

.contact-button {
    padding: 10px 70px; /* Smaller padding for the send button */
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 0.9em; /* Smaller font size for the button */
    align-self: center; /* Center the button horizontally */
}

.contact-button:hover {
    background-color: #0056b3;
}

.feedback-message {
    margin-top: 10px;
    font-size: 1em;
}

.feedback-message.success {
    color: green;
}

.feedback-message.error {
    color: red;
}

/* Media query for mobile devices */
@media (max-width: 700px) {
    .author-text h2 {
        margin-top: 20px; /* Adjust this value to move the "Welcome" text closer */
    }

    .page-content.home {
        width: 100%; /* Ensure it takes full width */
        padding: 0 10px; /* Adjust padding */
        box-sizing: border-box; /* Include padding in width */
    }

    .content-container {
        width: 100%;
        max-width: none;
        padding: 10px;
        box-shadow: none;
        object-fit: cover;
        overflow-x: hidden; /* Ensure no horizontal scroll */
    }

    .author-section,
    .special-thanks-section,
    .impact-section {
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .author-image,
    .impact-images,
    .thanks-image {
        margin-top: 20px;
        padding: 0;
        align-content: center;
    }

    .author-text,
    .impact-text,
    .thanks-text {
        padding: 0;
        text-align: left; /* Align the content text to the left */
    }

    .author-text h2,
    .impact-text h2,
    .special-thanks-section h2 {
        text-align: center; /* Keep the title text centered */
    }

    .shea-contact-image {
        width: 100%;
        max-width: 200px;
    }

    .project-image {
        max-width: 100%;
        margin-bottom: 10px;
    }

    .thanks-image {
        order: 2; /* Ensure the image is at the bottom */
    }

    .jennie-image img {
        max-width: 100%;
    }

    .special-thanks-content {
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Center the content */
    }

    .thanks-text {
        text-align: left; /* Center text for better alignment */
        margin-bottom: 10px; /* Add some space between text and image */
    }

    .thanks-image {
        order: 2; /* Ensure the image is displayed after the text */
        margin-top: 10px; /* Add space above the image */
    }

    .thanks-image img {
        width: 100%; /* Ensure the image takes full width */
        max-width: 200px; /* Optionally, limit the max width of the image */
        border-radius: 8px;
    }

    .hero-section img {
        height: 100%;
        width: 100%;
        margin-top: -50px; /* Add space above the image */
        object-fit: contain; /* Ensure the whole image fits within the container */
    }

    .author-section {
        margin-top: -100px; /* Add space above the image */
    }

    .contact-form {
        width: 100%; /* Ensure the form takes the full width on mobile */
    }

    .contact-form label {
        width: 100%; /* Ensure labels take full width */
    }

    .contact-form input,
    .contact-form textarea {
        width: calc(100% - 20px); /* Match the width of the input fields, accounting for padding */
        box-sizing: border-box; /* Include padding and border in the element's total width */
    }

    .contact-form textarea {
        height: 150px; /* Adjust height as needed */
    }

    .contact-content {
        font-size: 0.9em; /* Smaller font size for the button */
        padding: 10px;
    }

    .contact-form {
        width: 100%;
        max-width: none;
        padding: 10px;
    }
}