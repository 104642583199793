@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ece0d2;
    padding: 10px;
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling within the header */
}

.logo-container {
    padding: 10px;
    position: relative;
    width: 300px; /* Adjust width as needed */
    height: auto;
    background-color: #ece0d2; /* Background color for the logo container */
}

.logo {
    width: 100%;
    height: auto;
}

.menu-bar {
    display: flex;
    justify-content: center;
    background-color: #ece0d2; /* Updated background color for the menu bar */
    width: 100%;
}

.menu-link {
    color: #3498b0; /* Green color from the logo */
    text-decoration: none;
    margin: 0 15px;
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    transition: font-weight 0.3s, color 0.3s;
}

.menu-link:hover {
    color: #4CAF50; /* Vibrant orange color on hover */
}

.menu-link.active {
    font-size: 25px;
    font-weight: bold;
    color: #74a37f; /* Teal for active link */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .header {
        padding: 10px;
        width: 100%;
        box-shadow: none;
    }

    .logo-container {
        width: 200px; /* Adjust width for mobile as needed */
        padding: 5px;
    }

    .menu-bar {
        flex-direction: column;
        align-items: center;
        padding: 5px 0;
    }

    .menu-link {
        margin: 5px 0;
        font-size: 16px; /* Adjust font size for mobile as needed */
    }
}

/* Ensure the content fits within the screen */
.page-content.home {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling within the content */
}

.content-container {
    max-width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling within the container */
}
