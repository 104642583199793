@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

/* Define the fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

body {
    font-family: 'Poppins', sans-serif;
    color: #333;
    line-height: 1.7;
    margin: 0;
    padding: 0;
    background-color: #ece0d2;
}

.page-content.gallery-page {
    font-family: 'Poppins', sans-serif;
    color: #333;
    line-height: 1.7;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
    min-height: 100vh; /* Ensure the container takes up the full viewport height */
    width: 100%;
    background-color: #ece0d2;
}

h2 {
    font-family: 'Great Vibes', cursive;
    font-size: 2em;
}

.gallery-container {
    width: 95%; /* Adjust width to be slightly wider */
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 0%;
}

/* Apply fade-in animation to children */
.gallery-container > * {
    opacity: 0; /* Initially hidden */
    animation: fadeIn 1s ease-in-out forwards; /* Apply fade-in animation */
}

/* Gallery grid */
.gallery-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Adjust to 4 columns for larger images */
    gap: 20px; /* Increase gap for better spacing */
    width: 100%; /* Ensure the grid takes the full width of the container */
    height: auto; /* Set height to auto */
}

.gallery-item {
    width: 100%;
    height: 200px; /* Increase height for larger images */
    object-fit: cover;
    cursor: pointer;
    border-radius: 8px;
    transition: transform 0.2s;
}

.gallery-item:hover {
    transform: scale(1.05);
}

/* Modal */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    cursor: pointer;
}

.modal-content {
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
}

.close {
    position: absolute;
    top: 20px;
    right: 30px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .gallery-container {
        width: 100%; /* Make the container take the full width on mobile */
        padding: 10px;
        box-shadow: none;
        margin-top: 0%;
    }

    .gallery-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Increase min-width for larger images */
    }

    .gallery-item {
        height: auto; /* Increase height for larger images on mobile */
    }
}
