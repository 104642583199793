@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

/* Define the fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Apply fade-in animation to children */
.mission-content-container > * {
    opacity: 0; /* Initially hidden */
    animation: fadeIn 1s ease-in-out forwards; /* Apply fade-in animation */
}

body, .mission-page {
    font-family: 'Poppins', sans-serif;
    color: #333;
    line-height: 1.7;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center the content horizontally */
    min-height: 100vh; /* Ensure the container takes up the full viewport height */
    background-color: #ece0d2;
}

.mission-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
    background-color: #ece0d2;
}

.mission-content-container {
    max-width: 1000px;/* Adjust width to be slightly smaller */
    width: 100%;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box; /* Ensure padding is included in the width calculation */
    margin-top: 0%;
}

.mission-section {
    margin-bottom: 40px;
}

.mission-section h2 {
    color: #000000; /* Teal for section headings */
    font-size: 3em;
    margin-bottom: 20px;
}

.mission-section p,
.mission-section ul {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
}

.mission-section ul {
    list-style-type: none;
    padding: 0;
}

.mission-section ul li {
    background: #FFFFFF;
    padding: 15px;
    margin-bottom: 10px;
    border-left: 5px solid #44e30d; /* Teal border for list items */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mission-section ul li strong {
    color: #2C3E50; /* Dark blue for strong text */
}

/* Media query for mobile devices */
@media (max-width: 700px) {
    .mission-content-container {
        width: 100%; /* Adjust width to be slightly smaller on mobile */
        box-shadow: none;
        margin-top: 0%;
        overflow-x: hidden; /* Ensure no horizontal scroll */
    }

    .mission-section {
        padding: 10px;
    }

    .mission-section h2 {
        font-size: 1.5em; /* Make the h2 tags smaller */
        text-align: center; /* Center the h2 tags */
    }
}
