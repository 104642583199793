.footer {
    background-color: #ece0d2;
    text-align: center;
    padding: 10px;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: none; /* Initially hidden */
    z-index: 1000;
}

.footer.visible {
    display: block; /* Visible when the user reaches the bottom */
}

.footer a {
    color: #007bff;
    text-decoration: none;
    cursor: pointer;
}

.footer a:hover {
    text-decoration: underline;
}

.privacy-policy-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.privacy-policy-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}
